<template>
  <div class="iss-main-grid">
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :allow-selection="false"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :pagination="false"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #status="{ record }">
          <a-switch
              v-model:checked="record.status"
              checked-children="启用"
              un-checked-children="禁用"
              @click="handleClickSwitch(record)"
          />
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
      <a-modal
        v-model:visible="visible"
        title="编辑"
        cancelText="取消"
        okText="确认"
        :width="600"
        centered
        @ok="handleOk"
      >
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :model="form"
        >
          <a-form-item label="任务标题" v-bind="validateInfos.title">
            <a-input v-model:value="form.title" placeholder="请输入任务标题" />
          </a-form-item>
          <a-form-item label="完成数量" v-bind="validateInfos.count">
            <a-input v-model:value="form.count" placeholder="请输入完成数量" />
          </a-form-item>
          <a-form-item label="奖励金币" v-bind="validateInfos.giveGold" class="flex-row">
            <a-input
              v-model:value="form.giveGold"
              placeholder="请输入奖励金额"
            />
            <text class="row-uni">个</text>
          </a-form-item>
          <a-form-item label="启用状态">
            <a-switch
              v-model:checked="form.status"
              checked-children="启用"
              un-checked-children="禁用"
            />
          </a-form-item>
          <a-form-item label="任务描述" v-bind="validateInfos.description">
            <a-textarea
              v-model:value="form.description"
              placeholder="请输入任务描述"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
  <script>
import { reactive, toRefs, ref, toRaw } from 'vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import { Form, Switch, message } from 'ant-design-vue';
import configurationApi from '@/api/configuration.js';
export default {
  components: {
    Operation,
    Grid,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
    });
    let form = reactive({
      title: '',
      count: '',
      giveGold: '',
      description: '',
      status: true,
    });
    const checkOne= (rule, value) => {
      let num = Number(value)
      if (value && !(!isNaN(num) && num >= 1 && Number.isInteger(num))) {
        return Promise.reject('输入值必须为大于0的整数');
      }else if(num>9999){
        return Promise.reject('输入值必须为小于10000的整数');
      }
      return  Promise.resolve();
    };
    const required = { required: true, message: '不能为空' };

    const { validate, validateInfos } = Form.useForm(form, {
      title: [required],
      description: [required],
      count: [
        required,
        {
          validator:  checkOne,
          trigger: ['blur', 'change'],
        },
      ],
      giveGold: [
        required,
        {
          validator: checkOne,
          trigger: ['blur', 'change'],
        },
      ],
    });
    return {
      gridRef,
      ...toRefs(state),
      form,
      validateInfos,
      items: [
        { key: 'title', label: '任务标题' },
        { key: 'description', label: '任务描述' },
        { key: 'updateTime', label: '最后更新时间' },
        { key: 'count', label: '完成数量' },
        { key: 'giveGold', label: '金币奖励' },
        {
          key: 'status',
          label: '启用状态',
          dataset: [
            { value: '0', label: '启用' },
            { value: '1', label: '禁用' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: '',
          title: '序号',
          width: 60,
          ellipsis: true,
          align: 'center',
          customRender: ({ index }) => `${index + 1}`,
        },
        {
          dataIndex: 'title',
          title: '任务标题',
          width: 180,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'description',
          title: '任务描述',
          width: 240,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'updateTime',
          title: '最后更新时间',
          width: 160,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'count',
          title: '完成数量',
          width: 80,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'giveGold',
          title: '金币奖励',
          width: 80,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'status',
          title: '启用状态',
          width: 140,
          slots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          align: 'center',
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '修改',
          icon: 'EditTwoTone',
          permission: 'contact:update',
          fnClick: record => {
            Object.assign({},form );
            state.visible = true;
            Object.assign(form, record);
            console.log(form);
          },
        },
        // {
        //   type: 'delete',
        //   label: '删除',
        //   icon: 'DeleteTwoTone',
        //   permission: 'contact:delete',
        //   fnClick: record => {
        //     record.status=0
        //     configurationApi
        //       .updateOrDelete(`configuration:delete`, record)
        //       .then(() => {
        //         state.visible = false;
        //         message.success('操作成功');
        //         gridRef.value.refreshGrid();
        //       });
        //   },
        // },
      ],
      url: configurationApi.novicePageUrl,
      gridHeight: document.body.clientHeight - 386,
      handleOk() {
        // 触发子更新数据
        validate().then(() => {
          configurationApi
            .updateOrDelete(`configuration:update`, toRaw(form))
            .then(() => {
              state.visible = false;
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
        });
      },
      handleClickSwitch(record) {
        configurationApi.updateStatus('', {
          id: record.id,
          enable: record.enable
        }).then((res) => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
        let data = {...toRaw(record)}
        data.status = record.status
        configurationApi
            .updateOrDelete(`configuration:update`, data)
            .then((res) => {
              if (res) {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              }

            });


      }
    };
  },
};
</script>
  <style lang="less" scoped>
.flex-row {
  /deep/.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
}
/deep/ .ant-table-body{
    max-height: 70vh !important;
  }
  .flex-row {
  position: relative;
  .row-uni {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
}
</style>